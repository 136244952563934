// @flow
import * as React from 'react'
import { connect } from 'react-redux'

import Container from '../Container/Container'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import { ErrorDialogActions } from '../../../pages/slider/components/ErrorDialogActions'

import AlertDialog from '../Dialog/AlertDialog'
import { getDialog, closeErrorDialog } from '../../../ducks/globalDialog'
import { Logout } from '../../../ducks/authentication'

type Props = {
  children?: React.Node,
  Logout: () => void,
  closeErrorDialog: () => void,
  dialogState: Object,
}

class AppTemplate extends React.Component<Props> {
  handleClose = () => {
    this.props.closeErrorDialog()
    return this.props.Logout()
  }

  render() {
    const { children, dialogState } = this.props
    return (
      <Container>
        <Header />
        <div
          style={{
            paddingTop: 66,
            display: 'flex',
            width: '100%',
          }}
        >
          <Sidebar />
          <div className="container-content">
            {children}
            <AlertDialog {...dialogState}>
              <ErrorDialogActions onCloseHandler={this.handleClose} />
            </AlertDialog>
          </div>
        </div>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  dialogState: getDialog(state),
})

const mapDispatchToProps = {
  Logout,
  closeErrorDialog,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppTemplate)

// @flow
import React from 'react'
import themeConfig from '../../../config/theme'
import { FormHelperText } from '@material-ui/core'

export const CharCountHelper = ({
  length,
  maxLength,
}: {
  length: number,
  maxLength: number,
}) => (
  <FormHelperText
    style={{
      textAlign: 'right',
      fontSize: 12,
      color: `${
        length > maxLength
          ? themeConfig.palette.error.main
          : themeConfig.palette.primary.main
      }`,
    }}
  >
    {`${length} / ${maxLength}`}
  </FormHelperText>
)

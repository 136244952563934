// @flow
import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { LEGACY_POLL_LIST_URL } from '../../../commons/helpers/urls'

export const SuccessDialogActions = ({
  onCloseHandler,
}: {
  onCloseHandler: (SyntheticInputEvent<EventTarget>) => void,
}) => (
  <Grid container justify="flex-end" spacing={8}>
    <Grid item>
      <Button variant="outlined" onClick={onCloseHandler} size="large">
        Continue Editing
      </Button>
    </Grid>
    <Grid item>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        href={LEGACY_POLL_LIST_URL}
      >
        Go to Poll List
      </Button>
    </Grid>
  </Grid>
)

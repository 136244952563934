// TODO MOVE THIS TO THE BACKEND
const selectValues = [
  { value: 'None', label: 'None' },
  { value: '€', label: '€' },
  { value: '$', label: '$' },
  { value: '£', label: '£' },
  { value: 'Fr', label: 'Fr' },
  { value: 'CHF', label: 'CHF' },
  { value: '%', label: '%' },
  { value: '℃', label: '℃' },
  { value: '★', label: '★' },
  { value: '♥', label: '♥' },
  { value: 'km', label: 'km' },
  { value: 'km/h', label: 'km/h' },
  { value: 'mp/h', label: 'mp/h' },
  { value: 'Tore', label: 'Tore' },
]

export default selectValues

// @flow
import type { Dispatch as ReduxDispatch } from 'redux'
import { createActions, createReducer } from 'reduxsauce'
import { createSelector } from 'reselect'
import { getRoot } from './rootSelector'

type DialogState = {
  open: boolean,
  title: string,
  content: string,
}

/*
 * Action types
 */
export const { Types, Creators } = createActions({
  openErrorDialog: ['payload'],
  closeErrorDialog: null,
})

/*
 * Selectors
 */
export const getDialog = createSelector(
  getRoot,
  ({ theme }) => theme || INITIAL_STATE
)

/*
 * Thunks
 */
export const openErrorDialog = (payload: DialogState) => async (
  dispatch: ReduxDispatch
) => dispatch(Creators.openErrorDialog(payload))

export const closeErrorDialog = () => async (dispatch: ReduxDispatch) =>
  dispatch(Creators.closeErrorDialog())

/*
 * Reducer
 */
export const INITIAL_STATE = {
  open: false,
  title: '',
  content: '',
}

// $FlowFixMe
export const openErrorDialogHandler = (state = INITIAL_STATE) => ({
  ...state,
  open: true,
  title: 'Session expired',
  content: 'this session is not valid anymore. Please login again.',
})

// $FlowFixMe
export const closeErrorDialogHandler = () => INITIAL_STATE

export const HANDLERS = {
  [Types.OPEN_ERROR_DIALOG]: openErrorDialogHandler,
  [Types.CLOSE_ERROR_DIALOG]: closeErrorDialogHandler,
}

export default createReducer(INITIAL_STATE, HANDLERS)

import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import thunk from 'redux-thunk'
import userReducer from './ducks/user'
import loginReducer from './ducks/authentication'
import sliderReducer from './ducks/slider'
import globalDialog from './ducks/globalDialog'

export const history = createBrowserHistory()

const appReducer = combineReducers({
  router: connectRouter(history),
  user: userReducer,
  login: loginReducer,
  slider: sliderReducer,
  globalDialog: globalDialog,
})

const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT_REQUEST_SUCCESS') {
    state = {
      ...appReducer({}, {}),
      router: (state && state.router) || {},
    }
  }

  return appReducer(state, action)
}

export default (() => {
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk))
  )
  store.async = {}
  return store
})()

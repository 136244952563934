export default theme => ({
  root: {
    padding: 0,
  },
  select: {
    backgroundColor: theme.palette.common.white,
    '&:focus': {
      backgroundColor: theme.palette.common.white,
    },
  },
  inputRoot: {
    fontWeight: 'bold',
    color: theme.palette.grey[75],
    backgroundColor: theme.palette.lightGrey[25],
    borderColor: theme.palette.lightGrey.main,
  },
  sliderInput: {
    maxWidth: 160,
    maxHeight: 40,
    '&:focus': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary[25],
    },
  },
  incrementsInput: {
    backgroundColor: theme.palette.common.white,
  },
  helperText: {
    margin: 0,
    marginTop: 10,
  },
  error: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error[25],
  },
  focused: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary[25],
  },
  pollQuestion: {
    minHeight: '120px',
  },
  questionText: {
    minHeight: '64px',
    color: 'rgba(59, 60, 71, 0.75)',
    fontSize: '24px',
    textAlign: 'center',
    lineHeight: '32px',
  },
  slider: {
    minHeight: '500px',
  },
})

import { createActions, createReducer } from 'reduxsauce'
import { push } from 'connected-react-router'
import { login } from '../api/api'
import { Creators as UserCreators, saveUser } from './user'
import { createSelector } from 'reselect'
import { getRoot } from './rootSelector'
import { deleteAuthCookie } from '../storage/cookies'

/*
 * Action types
 */
export const { Types, Creators } = createActions({
  logoutRequest: null,
  loginRequest: null,
  loginRequestError: ['payload'],
  loginRequestSuccess: ['payload'],
  logOutRequestSuccess: ['payload'],
})

/*
 * Selectors
 */
export const getLoginRoot = createSelector(
  getRoot,
  ({ login }) => login || {}
)

export const getLoginLoadingState = createSelector(
  getLoginRoot,
  ({ isLoading }) => isLoading || false
)

/*
 * Thunks
 */
export const fetchLogin = payload => async dispatch => {
  dispatch(Creators.loginRequest())
  try {
    const response = await login(payload)

    await dispatch(saveUser(response))
    await dispatch(Creators.loginRequestSuccess(response))
  } catch (error) {
    // TODO: Handle multiple error messages
    dispatch(Creators.loginRequestError(error.errors))
  }
}

export const Logout = () => async dispatch => {
  dispatch(Creators.logoutRequest())
  try {
    await sessionStorage.clear()
    await localStorage.clear()
    await deleteAuthCookie()
    dispatch(UserCreators.removeUserSuccess())
    dispatch(Creators.logOutRequestSuccess())
    dispatch(push('/login'))
  } catch (error) {
    // eslint-disable-next-line
    console.log(error)
  }
}

/*
 *  Reducers
 */
export const INITIAL_STATE = {
  isLoading: false,
  errorMessage: '',
  redirectToReferrer: false,
}

export const loginHandler = (state = INITIAL_STATE) => ({
  ...state,
  isLoading: true,
})

export const loginSuccess = (state = INITIAL_STATE) => ({
  ...state,
  isLoading: false,
  redirectToReferrer: true,
})

export const loginError = (state = INITIAL_STATE, action) => ({
  ...state,
  isLoading: false,
  errorMessage: action.payload,
})

export const logoutSuccess = () => INITIAL_STATE

export const logoutHandler = () => INITIAL_STATE

export const HANDLERS = {
  [Types.LOGIN_REQUEST]: loginHandler,
  [Types.LOGOUT_REQUEST]: logoutHandler,
  [Types.LOGIN_REQUEST_ERROR]: loginError,
  [Types.LOGIN_REQUEST_SUCCESS]: loginSuccess,
  [Types.LOG_OUT_REQUEST_SUCCESS]: logoutSuccess,
}

export default createReducer(INITIAL_STATE, HANDLERS)

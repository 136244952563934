//@flow
import React, { Component } from 'react'
import {
  CircularProgress,
  IconButton,
  withStyles,
  DialogActions,
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'

type Props = {
  title: string,
  open: boolean,
  content: string,
  classes: Object,
  isLoading: boolean,
  handleClose: Function,
  children: any,
}

const styles = theme => ({
  dialogTitle: {
    margin: 0,
    minWidth: 600,
    '& h6': {
      color: theme.palette.common.white,
      fontSize: theme.typography.fontSize + 2,
    },
    backgroundColor: theme.palette.grey[800],
    textTransform: 'uppercase',
    padding: `${theme.spacing(2)}px  ${theme.spacing(3)}px`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
    color: theme.palette.grey[500],
  },
  loaderContainer: {
    padding: theme.spacing(6),
  },
  dialogContent: {
    padding: `${theme.spacing(4)}px  ${theme.spacing(3)}px`,
  },
  actionsContainer: {
    padding: `0px ${theme.spacing(4)}px  ${theme.spacing(4)}px`,
  },
})

class AlertDialog extends Component<Props> {
  render() {
    const {
      open,
      title,
      content,
      classes,
      children,
      isLoading,
      handleClose,
    } = this.props
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isLoading ? (
          <div className={classes.loaderContainer}>
            <CircularProgress size={24} />
          </div>
        ) : (
          <div>
            {handleClose && (
              <IconButton
                aria-label="Close"
                className={classes.closeButton}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            )}
            <DialogTitle
              className={classes.dialogTitle}
              id="alert-dialog-title"
            >
              {title}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              {content}
            </DialogContent>
            {children && (
              <DialogActions className={classes.actionsContainer}>
                {children}
              </DialogActions>
            )}
          </div>
        )}
      </Dialog>
    )
  }
}

export default withStyles(styles)(AlertDialog)

import React from 'react'
import './sidebar.css'
import Icons from '../Icons/Icons'
const Sidebar = () => {
  return (
    <div className="sidebar">
      <ul>
        <li className={'sidebar-item'}>
          <a href="/">
            <Icons.Tools />
            Tools
          </a>
        </li>
        <li className={'sidebar-item'}>
          <a href="/">
            <Icons.Config />
            Settings
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Sidebar

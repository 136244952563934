// @flow
import type { Dispatch as ReduxDispatch } from 'redux'
import { createActions, createReducer } from 'reduxsauce'
import { createSelector } from 'reselect'
import { loadAuthState, saveState } from '../storage/storage'
import { setAuthCookie } from '../storage/cookies'
import { getRoot } from './rootSelector'

type UserState = {
  user: {
    tokenType: string,
    accessToken: string,
    account: {
      id: number,
      scopes: [],
      name: string,
      email: string,
      admin: boolean,
      customer: string,
      language: string,
    },
  },
}

/*
 * Action types
 */
export const { Types, Creators } = createActions({
  saveUserSuccess: ['payload'],
  removeUserSuccess: null,
})

/*
 * Selectors
 */
export const getUser = createSelector(
  getRoot,
  ({ user }) => user || {}
)

export const getUserAccount = createSelector(
  getUser,
  ({ account }) => account || {}
)

export const getUserScopes = createSelector(
  getUserAccount,
  ({ scopes }) => scopes || []
)
// TODO VALIDATION FUNCTION
export const isAthenticated = createSelector(
  getUser,
  ({ accessToken }) => (accessToken ? true : false)
)

/*
 * Thunks
 */
export const saveUser = (user: Object) => async (dispatch: ReduxDispatch) => {
  try {
    await saveState(user)
    await setAuthCookie(user.accessToken)
    dispatch(Creators.saveUserSuccess(user))
  } catch (error) {
    // eslint-disable-next-line
    console.log(error)
  }
}

/*
 * Reducer
 */
export const INITIAL_STATE = {
  accessToken: '',
  tokenType: '',
  account: {
    admin: false,
    customer: '',
    email: '',
    id: 0,
    language: '',
    name: '',
  },
}

export const PreloadUser = (): UserState => loadAuthState() || INITIAL_STATE

// $FlowFixMe
export const saveUserHandler = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.payload,
})

// $FlowFixMe
export const removeUserHandler = () => INITIAL_STATE

export const HANDLERS = {
  [Types.SAVE_USER_SUCCESS]: saveUserHandler,
  [Types.REMOVE_USER_SUCCESS]: removeUserHandler,
}

export default createReducer(PreloadUser(), HANDLERS)

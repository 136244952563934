import { createActions, createReducer } from 'reduxsauce'
import { createSlider } from '../api/api'
import { createSelector } from 'reselect'
import { getRoot } from './rootSelector'

/*
 * Action types
 */
export const { Types, Creators } = createActions({
  closeDialog: null,
  setSubmitForm: null,
  resetSubmitForm: null,
  createSliderRequest: null,
  createSliderError: ['payload'],
  createSliderSuccess: ['payload'],
})

/*
 * Selectors
 */
export const getSliderRoot = createSelector(
  getRoot,
  ({ slider }) => slider || {}
)

export const getSliderDialogState = createSelector(
  getSliderRoot,
  ({ dialog }) => dialog || INITIAL_STATE.dialog
)

export const getFormTriggerState = createSelector(
  getSliderRoot,
  ({ submit }) => submit || INITIAL_STATE.submit
)

/*
 * Thunks
 */
export const submitSliderForm = payload => async dispatch => {
  dispatch(Creators.createSliderRequest())
  try {
    let { min, max, steps } = payload

    max = parseInt(max, 10)
    min = parseInt(min, 10)
    steps = parseInt(max, 10)

    const response = await createSlider({
      ...payload,
      max,
      min,
      steps,
    })
    await dispatch(Creators.createSliderSuccess(response))
  } catch ({ code, errors }) {
    dispatch(Creators.createSliderError(errors))
  }
}

export const setSubmit = () => async dispatch =>
  dispatch(Creators.setSubmitForm())

export const resetSubmit = () => async dispatch =>
  dispatch(Creators.resetSubmitForm())

export const closeDialogHandler = () => async dispatch =>
  dispatch(Creators.closeDialog())

/*
 *  Reducers
 */
export const INITIAL_STATE = {
  min: '',
  max: '',
  name: '',
  unit: '',
  steps: 0,
  type: '',
  tags: [],
  question: '',
  submit: false,
  dialog: {
    open: false,
    title: '',
    content: '',
    isLoading: false,
  },
}

export const setSubmitForm = (state = INITIAL_STATE) => ({
  ...state,
  submit: true,
})

export const resetSubmitForm = (state = INITIAL_STATE) => ({
  ...state,
  submit: false,
})

export const createSliderRequest = (state = INITIAL_STATE) => ({
  ...state,
  dialog: {
    open: true,
    isLoading: true,
  },
})

export const createSliderSuccess = (state = INITIAL_STATE) => ({
  ...state,
  dialog: {
    open: true,
    isLoading: false,
    title: 'Poll saved',
    content: 'Poll saved with success',
    success: true,
  },
})

export const createSliderError = (state = INITIAL_STATE, action) => ({
  ...state,
  dialog: {
    open: true,
    isLoading: false,
    title: 'Cannot save poll',
    content: action.payload,
    success: false,
  },
})

export const closeDialog = (state = INITIAL_STATE) => ({
  ...state,
  dialog: {
    open: false,
    title: '',
    content: '',
    isLoading: false,
    success: state.dialog.success,
  },
})

export const HANDLERS = {
  [Types.CLOSE_DIALOG]: closeDialog,
  [Types.SET_SUBMIT_FORM]: setSubmitForm,
  [Types.RESET_SUBMIT_FORM]: resetSubmitForm,
  [Types.CREATE_SLIDER_ERROR]: createSliderError,
  [Types.CREATE_SLIDER_REQUEST]: createSliderRequest,
  [Types.CREATE_SLIDER_SUCCESS]: createSliderSuccess,
}

export default createReducer(INITIAL_STATE, HANDLERS)

// @flow
import React from 'react'
import { FormControl } from '@material-ui/core'
import { TextValidator } from 'react-material-ui-form-validator'

import { CharCountHelper } from './CharCount'
import ValidationMessages from '../../../commons/helpers/validationMessages'
import validations from '../validations'

export const MinimumValueInput = ({
  classes,
  min,
  onchangeHandler,
}: {
  classes: Object,
  min: string,
  onchangeHandler: (SyntheticInputEvent<EventTarget>) => void,
}) => {
  const { required, notEmpty, isNumber, minNumber } = ValidationMessages
  const { tenChar } = validations
  const { inputRoot, error, sliderInput } = classes
  const validators = [
    'required',
    'minStringLength:1',
    'isNumber',
    'minNumber:1',
    'maxStringLength:10',
  ]
  return (
    <FormControl>
      <TextValidator
        variant="outlined"
        name="min"
        value={min}
        onChange={onchangeHandler}
        placeholder="Minimum value"
        InputProps={{
          classes: { root: inputRoot, error: error },
        }} /* eslint-disable-next-line react/jsx-no-duplicate-props */
        inputProps={{ className: sliderInput }}
        validators={validators}
        errorMessages={[required, notEmpty, isNumber, minNumber, tenChar]}
      />
      <br />
      <CharCountHelper length={min.length} maxLength={10} />
    </FormControl>
  )
}

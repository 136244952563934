// AXIOS
import axios from 'axios'
import { DASHBOARD_API_URL, API_LOGIN_URL, API_POLLS_SLIDER } from './urls'
import { loadAuthState } from '../storage/storage'
import store from '../store'
import { openErrorDialog } from '../ducks/globalDialog'

export const API = axios.create({
  baseURL: DASHBOARD_API_URL,
  timeout: 10000,
  headers: { 'content-type': 'application/json' },
})

// request interceptor
API.interceptors.request.use(
  config => {
    if (config.url !== API_LOGIN_URL) {
      const { accessToken } = loadAuthState() || {}
      if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  error => Promise.reject(error)
)

// response interceptor
API.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      store.dispatch(openErrorDialog())
      return Promise.reject(error.response.data)
    }
    if (error.response) {
      return Promise.reject(error.response.data)
    }
    return Promise.reject(error)
  }
)

export const login = ({ login, password }) =>
  API.post(API_LOGIN_URL, { login, password }).then(response => response.data)

export const createSlider = slider =>
  API.post(API_POLLS_SLIDER, { ...slider }).then(response => response.data)

// @flow
import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { FormControl } from '@material-ui/core'
import { SelectValidator } from 'react-material-ui-form-validator'

import selectValues from '../sliderValues'

export const SliderUnitsInput = ({
  classes,
  onchangeHandler,
  unit,
}: {
  unit: Object,
  classes: Object,
  onchangeHandler: (SyntheticInputEvent<EventTarget>) => void,
}) => {
  const { select, helperText } = classes
  return (
    <FormControl>
      <label htmlFor="unit">Slider units</label>
      <SelectValidator
        select
        fullWidth
        name="unit"
        value={unit}
        variant="outlined"
        onChange={onchangeHandler}
        inputProps={{ className: select }}
        FormHelperTextProps={{ className: helperText }}
      >
        {selectValues.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </SelectValidator>
    </FormControl>
  )
}

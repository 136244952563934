// @flow
import * as React from 'react'
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { connect } from 'react-redux'

import Icons from '../../../commons/ui/Icons/Icons'
import { titleToId } from '../../../commons/helpers'
import { PollQuestion } from './PollQuestion'
import { IncrementsInput } from './IncrementsInput'
import { SliderUnitsInput } from './SliderUnitsInput'
import { MaximumValueInput } from './MaximumValueInput'
import { MinimumValueInput } from './MinimumValueInput'
import { resetSubmit, getFormTriggerState } from '../../../ducks/slider'

import style from '../style'

const styles = theme => style(theme)

type State = {
  form: {
    min: string,
    max: string,
    name: string,
    unit: string,
    steps: 0,
    question: string,
    type: string,
  },
}

type Props = {
  classes: Object,
  onSubmit: Function,
  submitForm: boolean,
  resetSubmit: Function,
}

class SliderForm extends React.Component<Props, State> {
  state = {
    form: {
      min: '',
      max: '',
      name: '',
      unit: '',
      steps: 0,
      question: '',
      type: 'slider',
    },
  }

  formRef: any = React.createRef()

  handleChange = event => {
    const { form } = this.state
    if (event.target) {
      form[event.target.name] = event.target.value
      if (event.target.name === 'question') {
        form.name = titleToId(form.question)
      }
      this.setState({ form })
    }
  }

  handleSubmit = async () => {
    // False on isFormValid is to show validations on submit
    // https://github.com/NewOldMax/react-form-validator-core#validation-component-for-react-forms
    const validForm = await this.formRef.current.isFormValid(false)
    if (validForm) {
      this.props.onSubmit(this.state.form)
    }
  }
  // adding listener for save button actions
  async componentDidUpdate() {
    if (this.props.submitForm === true) {
      this.handleSubmit()
      this.props.resetSubmit()
    }
  }

  render() {
    const { form } = this.state
    const { classes } = this.props

    return (
      <ValidatorForm
        instantValidate={true}
        ref={this.formRef}
        onSubmit={this.handleSubmit}
        autoComplete="off"
      >
        <div className={classes.slider}>
          <PollQuestion
            classes={classes}
            question={form.question}
            onchangeHandler={this.handleChange}
          />
          <Icons.Slider className="poll-slider-icon" />
          <div className="poll-sections">
            <MinimumValueInput
              classes={classes}
              min={form.min}
              onchangeHandler={this.handleChange}
            />
            <MaximumValueInput
              classes={classes}
              max={form.max}
              onchangeHandler={this.handleChange}
            />
          </div>
        </div>
        <div className="slider-config">
          <Grid item xs={6}>
            <SliderUnitsInput
              unit={form.unit}
              classes={classes}
              onchangeHandler={this.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <IncrementsInput
              classes={classes}
              steps={form.steps}
              onchangeHandler={this.handleChange}
            />
          </Grid>
        </div>
      </ValidatorForm>
    )
  }
}

const SliderWithStyles = withStyles(styles)(SliderForm)

const mapStateToProps = state => ({
  submitForm: getFormTriggerState(state),
})

const mapDispatchToProps = {
  resetSubmit,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SliderWithStyles)

import { getAuthCookie } from './cookies'

export const INITIAL_STATE = {
  accessToken: '',
  tokenType: '',
  account: {
    admin: false,
    customer: '',
    email: '',
    id: 0,
    language: '',
    name: '',
  },
}

export const loadAuthState = () => {
  try {
    const serializedState = localStorage.getItem('auth')
    if (serializedState === null) {
      const cookie = getAuthCookie()
      const UserState = cookie
        ? {
            ...INITIAL_STATE,
            accessToken: cookie,
          }
        : undefined
      return UserState
    }

    return {
      ...INITIAL_STATE,
      accessToken: serializedState,
    }
  } catch (err) {
    return undefined
  }
}

export const saveState = state => {
  try {
    localStorage.setItem('auth', state.accessToken)
  } catch {
    // ignore write errors
  }
}

// @flow
import React from 'react'
import { FormControl } from '@material-ui/core'
import { TextValidator } from 'react-material-ui-form-validator'

import validations from '../validations'
import ValidationMessages from '../../../commons/helpers/validationMessages'

export const IncrementsInput = ({
  onchangeHandler,
  steps,
  classes,
}: {
  classes: Object,
  steps: number,
  onchangeHandler: (SyntheticInputEvent<EventTarget>) => void,
}) => {
  const { required, notEmpty, isNumber, minNumber } = ValidationMessages
  const { overTwenty } = validations
  const { incrementsInput, helperText } = classes
  return (
    <FormControl>
      <label htmlFor="">Number of steps</label>
      <TextValidator
        placeholder="0"
        name="steps"
        variant="outlined"
        value={steps}
        onChange={onchangeHandler}
        validators={[
          'required',
          'minStringLength:1',
          'isNumber',
          'minNumber:1',
          'maxNumber:20',
        ]}
        errorMessages={[required, notEmpty, isNumber, minNumber, overTwenty]}
        inputProps={{ className: incrementsInput }}
        FormHelperTextProps={{ className: helperText }}
      />
    </FormControl>
  )
}

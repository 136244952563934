// @flow
import { Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { fetchLogin, getLoginLoadingState } from '../../ducks/authentication'
import logo from '../../images/Opinary.svg'
import './login.css'
import { isAthenticated } from '../../ducks/user'

type Props = {
  isAthenticated: boolean,
  classes: Object,
  fetchLogin: Function,
  redirectToReferrer: boolean,
  errorMessage: string,
  history: Function,
  loginLoadingState: boolean,
  location: {
    state: {
      from: {},
    },
  },
}

type State = {
  login: string,
  password: string,
}

const styles = () => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  button: {
    display: 'block',
    width: '100%',
    color: '#fff',
  },
})

const validateForm = (login, password) => {
  let errors = {}
  if (password.length < 7) {
    errors.password = true
  }

  if (login.length === 0) {
    errors.login = true
  }

  return errors
}

class Login extends Component<Props, State> {
  state = {
    login: '',
    password: '',
  }

  handleChange = (e: Object) => {
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change)
  }

  componentDidMount = () => {
    if (this.props.isAthenticated) {
      this.props.history.push('/home')
    }
  }
  componentWillReceiveProps = () => {
    if (this.props.isAthenticated) {
      this.props.history.push('/home')
    }
  }

  loginHandler = ({ email, password }: { email: string, password: string }) =>
    this.props.fetchLogin({ login: email, password })

  render() {
    const {
      classes,
      errorMessage,
      loginLoadingState,
      redirectToReferrer,
    } = this.props
    const { login, password } = this.state
    const { from } = this.props.location.state || {
      from: { pathname: '/home' },
    }

    const hasErrors = validateForm(login, password)

    if (redirectToReferrer === true) {
      return <Redirect to={from} />
    }

    return (
      <div className="login">
        <div className="brand">
          <img src={logo} width="107" height="48px" alt="opinary logo" />
          <h2 className="heading">Welcome back!</h2>
        </div>

        <form>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <div className="inputContainer">
            <label htmlFor="email">EMAIL ADRESS:</label>
            <input
              id="email"
              className={hasErrors.login ? 'error' : 'input'}
              type="text"
              name="login"
              placeholder="e-mail"
              onChange={this.handleChange}
              value={login}
            />
          </div>
          <div className="forgotPass" />
          <div className="inputContainer">
            <label htmlFor="password">PASSWORD:</label>
            <input
              id="password"
              className={hasErrors.password ? 'error' : 'input'}
              type="password"
              name="password"
              placeholder="password"
              onChange={this.handleChange}
              value={password}
            />
          </div>
          <div className="inputContainer">
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              disabled={loginLoadingState}
              onClick={() => this.loginHandler({ email: login, password })}
            >
              Login
              {loginLoadingState && (
                <CircularProgress
                  className={classes.buttonProgress}
                  size={24}
                />
              )}
            </Button>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  redirectToReferrer: state.login.redirectToReferrer,
  errorMessage: state.login.errorMessage,
  loginLoadingState: getLoginLoadingState(state),
  isAthenticated: isAthenticated(state),
})

const mapDispatchToProps = {
  fetchLogin,
}

const LoginComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)

export default withStyles(styles)(withRouter(LoginComponent))

//@flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import SliderForm from './components/SliderForm'
import PollCanvas from '../../commons/ui/PollCanvas/PollCanvas'
import {
  submitSliderForm,
  getSliderDialogState,
  closeDialogHandler,
} from '../../ducks/slider'
import AlertDialog from '../../commons/ui/Dialog/AlertDialog'
import './slider.css'
import { SuccessDialogActions } from './components/SuccessDialogActions'
import { ErrorDialogActions } from './components/ErrorDialogActions'

type Props = {
  closeDialogHandler: () => void,
  submitSliderForm: Object => void,
  dialogState: Object,
}

class SliderPoll extends Component<Props> {
  handleFormSubmit = formState => this.props.submitSliderForm(formState)

  handleClose = () => this.props.closeDialogHandler()

  render() {
    const { dialogState } = this.props
    return (
      <PollCanvas>
        <SliderForm onSubmit={this.handleFormSubmit} />
        <AlertDialog {...dialogState}>
          {dialogState.success ? (
            <SuccessDialogActions onCloseHandler={this.handleClose} />
          ) : (
            <ErrorDialogActions onCloseHandler={this.handleClose} />
          )}
        </AlertDialog>
      </PollCanvas>
    )
  }
}

const mapStateToProps = state => ({
  dialogState: getSliderDialogState(state),
})

const mapDispatchToProps = {
  submitSliderForm,
  closeDialogHandler,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SliderPoll))

// @flow
import React from 'react'
import { FormControl } from '@material-ui/core'
import { TextValidator } from 'react-material-ui-form-validator'

import { CharCountHelper } from './CharCount'
import ValidationMessages from '../../../commons/helpers/validationMessages'

export const PollQuestion = ({
  question,
  onchangeHandler,
  classes,
}: {
  question: string,
  onchangeHandler: (SyntheticInputEvent<EventTarget>) => void,
  classes: Object,
}) => {
  const { required, notEmpty } = ValidationMessages
  const { pollQuestion, questionText, inputRoot, error, focused } = classes
  return (
    <FormControl name="sliderQuestion" fullWidth>
      <TextValidator
        multiline
        rowsMax={3}
        name="question"
        margin="normal"
        value={question}
        id="question-text"
        variant="outlined"
        className={pollQuestion}
        InputProps={{
          classes: { root: inputRoot, error: error, focused: focused },
        }} /* eslint-disable-next-line react/jsx-no-duplicate-props */
        inputProps={{ className: questionText }}
        onChange={onchangeHandler}
        errorMessages={[required, notEmpty, '']}
        validators={['required', 'minStringLength:1', 'maxStringLength:75']}
        placeholder="This is where you type your question for the slider tool"
      />
      <CharCountHelper length={question.length} maxLength={75} />
    </FormControl>
  )
}

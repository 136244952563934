// @flow
import React from 'react'
import { FormControl } from '@material-ui/core'
import { TextValidator } from 'react-material-ui-form-validator'

import { CharCountHelper } from './CharCount'
import ValidationMessages from '../../../commons/helpers/validationMessages'
import validations from '../validations'

export const MaximumValueInput = ({
  classes,
  max,
  onchangeHandler,
}: {
  classes: Object,
  max: string,
  onchangeHandler: (SyntheticInputEvent<EventTarget>) => void,
}) => {
  const { required, notEmpty, isNumber, minNumber } = ValidationMessages
  const { tenChar } = validations
  const { inputRoot, error, sliderInput } = classes
  return (
    <FormControl>
      <TextValidator
        name="max"
        variant="outlined"
        value={max}
        placeholder="Maximum value"
        onChange={onchangeHandler}
        InputProps={{
          classes: { root: inputRoot, error: error },
        }} /* eslint-disable-next-line react/jsx-no-duplicate-props */
        inputProps={{ className: sliderInput }}
        validators={[
          'required',
          'minStringLength:1',
          'isNumber',
          'minNumber:1',
          'maxStringLength:10',
        ]}
        errorMessages={[required, notEmpty, isNumber, minNumber, tenChar]}
      />
      <br />
      <CharCountHelper length={max.length} maxLength={10} />
    </FormControl>
  )
}

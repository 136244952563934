import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { unregister } from './registerServiceWorker'
import store, { history } from './store'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import { Routes } from './routes/routes'
import { ConnectedRouter } from 'connected-react-router'
import themeConfig from './config/theme'

import './index.css'

const theme = createMuiTheme(themeConfig)

export const Main = () => (
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </Provider>
  </MuiThemeProvider>
)

ReactDOM.render(<Main />, document.getElementById('root'))
unregister()

import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { isAthenticated } from '../ducks/user'

type Props = {
  isAthenticated: boolean,
  component: any,
  location: any,
}

type State = {}

class PrivateRoute extends Component<Props, State> {
  render() {
    const { component, isAthenticated, ...rest } = this.props
    const Component = component
    return (
      <Route
        {...rest}
        render={props =>
          isAthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          )
        }
      />
    )
  }
}

const mapStateToProps = state => ({
  isAthenticated: isAthenticated(state),
})

export default connect(
  mapStateToProps,
  null
)(PrivateRoute)

import Cookies from 'universal-cookie'

export const cookies = new Cookies()

export const config = {
  domain: '.opinary.com',
  path: '/',
  secure: true,
}

export const getAuthCookie = () => cookies.get('auth')

export const setAuthCookie = token => cookies.set('auth', token, config)

export const deleteAuthCookie = () => cookies.remove('auth', config)

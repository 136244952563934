import { createMuiTheme } from '@material-ui/core'

const theme = createMuiTheme({})

const palette = {
  common: {
    white: '#ffffff',
    black: '#000000',
  },
  primary: {
    main: '#faa722',
    25: 'rgba(255, 160, 0, 0.25)',
  },
  secondary: {
    main: '#3ABC98',
  },
  error: {
    main: '#EE3E37',
    25: 'rgba(238, 62, 55, 0.25)',
  },
  lightGrey: {
    main: '#d6dadf',
    25: 'rgba(214, 218, 223, 0.25)',
  },
  grey: {
    75: 'rgba(59, 60, 71, 0.75)',
  },
}

export const themeOverrides = {
  overrides: {
    MuiButton: {
      root: {
        minWidth: 130,
        boxShadow: 'none',
      },
      contained: {
        boxShadow: 'none',
      },
      containedSecondary: {
        color: palette.common.white,
      },
    },
    MuiFab: {
      extended: {
        minWidth: 130,
        boxShadow: 'none',
        fontWeight: 'bold',
        margin: theme.spacing(),
        textTransform: 'capitalize',
        color: palette.common.white,
        fontSize: theme.typography.fontSize + 2,
        backgroundColor: palette.primary.main,
        padding: `0 ${theme.spacing(6)}px`,
      },
    },
  },
  typography: {
    fontFamily: ['Lato', `sans-serif`].join(','),
  },
  shape: {
    borderRadius: 2,
  },
  palette,
}

export default {
  ...themeOverrides,
}

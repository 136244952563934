import React from 'react'
import { Route, Switch } from 'react-router-dom'
import AppTemplate from '../commons/ui/AppTemplate/AppTemplate'
import Login from '../pages/login/Login'
import SliderPoll from '../pages/slider/Slider'
import { Polllist } from '../pages/PollList'
import PrivateRoute from './privateRoute'

export const Routes = () => (
  <main>
    <Switch>
      <Route path="/login" component={Login} />
      <AppTemplate>
        <PrivateRoute exact={true} path={'/'} component={SliderPoll} />
        <PrivateRoute exact={true} path={'/home'} component={SliderPoll} />
        <PrivateRoute exact={true} path={'/list'} component={Polllist} />
      </AppTemplate>
    </Switch>
  </main>
)

// @flow
import React from 'react'
import { Button } from '@material-ui/core'

export const ErrorDialogActions = ({
  onCloseHandler,
}: {
  onCloseHandler: (SyntheticInputEvent<EventTarget>) => void,
}) => (
  <Button
    variant="contained"
    color="secondary"
    onClick={onCloseHandler}
    size="large"
  >
    Ok
  </Button>
)

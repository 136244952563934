import React from 'react'

const Tools = props => (
  <svg id={'icon'} width="30" height="30" viewBox="0 0 28 29" {...props}>
    <defs>
      <path id="prefix__a" d="M0 0h30v30H0z" />
    </defs>
    <g transform="translate(-1)" fill="none" fillRule="evenodd">
      <mask id="prefix__b" fill="#fff">
        <use xlinkHref="#prefix__a" />
      </mask>
      <g mask="url(#prefix__b)">
        <g fill="#D6DADF" transform="translate(.938 .938)">
          <path d="M24.375 3.281h2.281a1 1 0 0 1 1 1v.813a1 1 0 0 1-1 1h-2.281v2.281a1 1 0 0 1-1 1h-.813a1 1 0 0 1-1-1V6.094h-2.28a1 1 0 0 1-1-1V4.28a1 1 0 0 1 1-1h2.28V1a1 1 0 0 1 1-1h.813a1 1 0 0 1 1 1v2.281z" />
          <ellipse cx={14.063} cy={19.219} rx={1} ry={4.219} />
          <path
            d="M27.656 25.313a1.406 1.406 0 0 1-2.812 0v-1.875c0-5.955-4.827-10.782-10.782-10.782-5.954 0-10.78 4.827-10.78 10.781v1.875a1.406 1.406 0 0 1-2.813 0v-1.875c0-7.507 6.086-13.593 13.594-13.593 7.507 0 13.593 6.086 13.593 13.594v1.875z"
            fillRule="nonzero"
          />
          <circle cx={14.063} cy={24.375} r={2.813} />
        </g>
      </g>
    </g>
  </svg>
)

const Config = props => (
  <svg width="30" height="30" viewBox="0 0 30 30" {...props}>
    <path
      d="M29.844 12.412a.615.615 0 0 0-.41-.244l-3.574-.547a11.781 11.781 0 0 0-.801-1.914c.234-.325.585-.784 1.054-1.377.469-.592.8-1.025.996-1.299a.752.752 0 0 0 .156-.449c0-.182-.045-.325-.136-.43-.47-.664-1.543-1.77-3.223-3.32a.754.754 0 0 0-.488-.195.634.634 0 0 0-.469.175l-2.773 2.09a12.078 12.078 0 0 0-1.758-.723L17.87.586a.579.579 0 0 0-.225-.42.738.738 0 0 0-.478-.166h-4.336c-.378 0-.612.182-.703.547-.17.65-.358 1.862-.567 3.632-.612.196-1.204.443-1.777.743L7.09 2.832a.831.831 0 0 0-.508-.195c-.286 0-.902.465-1.846 1.396-.944.931-1.585 1.631-1.923 2.1-.118.169-.176.319-.176.449 0 .156.065.313.195.469.872 1.054 1.569 1.953 2.09 2.695a9.638 9.638 0 0 0-.762 1.797l-3.633.547a.589.589 0 0 0-.37.254.752.752 0 0 0-.157.449v4.336c0 .17.052.322.156.459.105.137.241.218.41.244l3.575.528c.182.638.449 1.282.8 1.933-.234.325-.585.784-1.054 1.377-.469.592-.801 1.025-.996 1.299a.764.764 0 0 0-.02.898c.508.703 1.582 1.797 3.223 3.281a.673.673 0 0 0 .488.215.703.703 0 0 0 .489-.175l2.754-2.09c.534.273 1.12.514 1.758.723l.546 3.593a.58.58 0 0 0 .225.42.736.736 0 0 0 .478.166h4.336c.379 0 .613-.182.704-.547.17-.65.358-1.862.566-3.632a11.147 11.147 0 0 0 1.777-.743l2.696 2.11c.182.117.352.175.508.175.286 0 .898-.462 1.835-1.386.938-.925 1.583-1.628 1.934-2.11a.651.651 0 0 0 .176-.449.753.753 0 0 0-.196-.488c-.937-1.146-1.634-2.045-2.09-2.696.26-.481.515-1.074.762-1.777l3.613-.547a.595.595 0 0 0 .391-.254.754.754 0 0 0 .156-.45v-4.335a.735.735 0 0 0-.156-.459zm-10.248 7.184C18.326 20.866 16.794 21.5 15 21.5s-3.326-.635-4.596-1.904C9.134 18.326 8.5 16.794 8.5 15s.635-3.326 1.904-4.596C11.674 9.134 13.206 8.5 15 8.5s3.327.635 4.596 1.904c1.27 1.27 1.904 2.802 1.904 4.596s-.635 3.326-1.904 4.596z"
      fill="#D6DADF"
      fillRule="evenodd"
    />
  </svg>
)

const Slider = props => (
  <svg width="100%" height={64} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M2.5 30h435a2.5 2.5 0 1 1 0 5H2.5a2.5 2.5 0 0 1 0-5z"
        opacity={0.452}
        fill="#D6DADF"
      />
      <g transform="translate(53 30.5)" fill="#3B3C47">
        <circle cx={331.5} cy={2} r={1.5} />
        <circle cx={276.5} cy={2} r={1.5} />
        <circle cx={221.5} cy={2} r={1.5} />
        <circle cx={166.5} cy={2} r={1.5} />
        <circle cx={111.5} cy={2} r={1.5} />
        <circle cx={56.5} cy={2} r={1.5} />
        <circle cx={1.5} cy={2} r={1.5} />
      </g>
      <g transform="translate(188)">
        <circle fill="#FFF" cx={32} cy={32} r={25} />
        <path
          d="M32 64C14.327 64 0 49.673 0 32 0 14.327 14.327 0 32 0c17.673 0 32 14.327 32 32 0 17.673-14.327 32-32 32zm0-2c16.569 0 30-13.431 30-30C62 15.431 48.569 2 32 2 15.431 2 2 15.431 2 32c0 16.569 13.431 30 30 30zm0-4.5C17.917 57.5 6.5 46.083 6.5 32S17.917 6.5 32 6.5 57.5 17.917 57.5 32 46.083 57.5 32 57.5zm4.865-30.206c0-1.02.089-2.134-.177-3.155-.088-1.949-1.238-3.434-3.007-3.898-.973-.278-1.946-.278-2.92-.185-2.3.278-3.715 1.856-3.803 4.269v2.97c-.708 1.206-.531 2.134.354 3.062.265 1.207.884 2.32 1.68 3.248v1.114l-.088.279c-.089.278-.53.556-.62.65l-1.238.835c-1.77 1.02-3.715 1.763-5.661 2.598-.708.371-.885.557-.885 1.392v2.228c0 1.02.354 1.299 1.327 1.299h20.346c1.062 0 1.327-.278 1.327-1.3v-2.134c0-.928-.089-1.114-.973-1.485-1.239-.464-2.389-1.02-3.627-1.578-.973-.464-1.946-.928-2.83-1.484-.532-.279-.974-.65-1.24-1.207v-1.207c.797-.928 1.328-2.041 1.681-3.248.885-.835 1.062-1.763.354-3.063z"
          fill="#D6DADF"
        />
      </g>
    </g>
  </svg>
)

export default {
  Config,
  Tools,
  Slider,
}

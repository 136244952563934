import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

import './header.css'
import { Logout } from '../../../ducks/authentication'
import { getUserScopes } from '../../../ducks/user'
import { Fab } from '@material-ui/core'
import { setSubmit } from '../../../ducks/slider'

type Props = {
  Logout: () => void,
}

const styles = theme => ({
  logo: {
    marginRight: theme.spacing(14),
  },
  header: {
    padding: `0 ${theme.spacing(3)}px`,
  },
})

export class Header extends Component<null, Props> {
  handlerLogout = () => this.props.Logout()

  handleSubmit = () => this.props.setSubmit()

  render() {
    const { scopes, classes } = this.props
    return (
      <header className={`header  ${classes.header}`}>
        <img
          src="https://image4.owler.com/logo/opinary_owler_20160404_110249_original.png"
          alt="opinary logo"
          className={`logo  ${classes.logo}`}
        />
        <nav>
          <Link to="/list">Polls</Link>
          <Link to="/">Campaigns</Link>
          {scopes.includes('admin') && <Link to="/admin">Admin</Link>}
        </nav>
        <Fab
          variant="extended"
          color="primary"
          onClick={this.handleSubmit}
          aria-label="Save"
        >
          Save poll
        </Fab>
        <button onClick={this.handlerLogout}>logout</button>
      </header>
    )
  }
}

const mapStateToProps = state => ({
  scopes: getUserScopes(state),
})

const mapDispatchToProps = {
  Logout,
  setSubmit,
}

const ConnectedHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)

export default withStyles(styles)(ConnectedHeader)

// @flow
import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import './pollCanvas.css'

type Props = {
  children?: React.Node,
}

class PollCanvas extends React.Component<Props> {
  render() {
    return (
      <div className="poll-canvas">
        <div className="poll-canvas-header">Create Poll</div>
        <div className="poll-canvas-container">
          <div className="poll-canvas-frame">
            <Grid container spacing={10}>
              <Grid item xs={12}>
                {this.props.children}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    )
  }
}

export default PollCanvas

import * as React from 'react'
import './container.css'
type Props = {
  children?: React.Node,
}
const Container = (props: Props) => (
  <div className="container">{props.children}</div>
)

export default Container

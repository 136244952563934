//@flow
export function titleToId(header: string): string {
  if (header === '') {
    return header
  }
  return [
    header
      .toLowerCase()
      .split(' ')
      .map(str => str.replace(/[^0-9a-z]/gi, ''))
      .join('-'),
  ].join('_')
}
